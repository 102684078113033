@media screen and (max-width: 767px) {
  .contents_wrapper {
    padding-bottom: 0;
  }
  .contents_wrapper + .popular {
    margin-top: 30px;
    border-top: none;
  }
}

@media screen and (max-width: 767px) {
  aside .mypage_menu_wrap {
    border: 2px solid #d0d0d0;
  }
}

aside .mypage_menu_wrap a, aside .mypage_menu_wrap span {
  display: block;
  text-decoration: none;
  padding: 5px 0;
  padding-left: 20px;
  padding-right: 5px;
  font-weight: bold;
}

@media screen and (max-width: 767px) {
  aside .mypage_menu_wrap a, aside .mypage_menu_wrap span {
    padding: 12px 0;
    padding-left: 10px;
    padding-right: 5px;
  }
}

aside .mypage_menu_wrap a {
  text-decoration: none;
}

@media screen and (min-width: 768px) {
  aside .mypage_menu_wrap a:hover {
    text-decoration: underline;
  }
}

aside .mypage_menu_wrap a.with_arrow_right svg {
  width: 9px;
}

@media screen and (max-width: 767px) {
  aside .mypage_menu_wrap a.with_arrow_right svg {
    right: 10px;
  }
}

aside .mypage_menu_wrap .mypage_menu {
  padding: 5px 0;
  border-bottom: 1px solid #d0d0d0;
}

@media screen and (max-width: 767px) {
  aside .mypage_menu_wrap .mypage_menu {
    padding: 0;
  }
}

aside .mypage_menu_wrap .mypage_menu:first-of-type {
  padding: 0;
  border-top: 1px solid #d0d0d0;
}

@media screen and (max-width: 767px) {
  aside .mypage_menu_wrap .mypage_menu:first-of-type {
    border-top: none;
  }
}

@media screen and (max-width: 767px) {
  aside .mypage_menu_wrap .mypage_menu:last-of-type {
    border-bottom: none;
  }
}

aside .mypage_menu_wrap .mypage_menu.current a {
  background-color: #efefef;
}

aside .mypage_menu_wrap .mypage_menu ul {
  padding: 5px 0;
}

@media screen and (max-width: 767px) {
  aside .mypage_menu_wrap .mypage_menu ul {
    padding: 0;
  }
}

aside .mypage_menu_wrap .mypage_menu ul li a {
  font-weight: normal;
  padding-left: 40px;
}

@media screen and (max-width: 767px) {
  aside .mypage_menu_wrap .mypage_menu ul li a {
    padding-left: 20px;
  }
}

@media screen and (max-width: 767px) {
  aside .mypage_menu_wrap .mypage_menu ul li:first-of-type {
    border-top: 1px dotted #d0d0d0;
  }
}

@media screen and (max-width: 767px) {
  aside .mypage_menu_wrap .mypage_menu ul li:not(:last-of-type) {
    border-bottom: 1px dotted #d0d0d0;
  }
}

aside .mypage_menu_wrap .mypage_menu ul li.current a {
  background-color: #efefef;
}

aside .mypage_point {
  margin-top: 20px;
  border: 1px solid #d0d0d0;
  padding: 10px;
}

@media screen and (max-width: 767px) {
  aside .mypage_point {
    display: none;
  }
}

aside .mypage_point p:first-of-type {
  border-bottom: 1px dotted #d0d0d0;
  font-size: 13px;
  font-weight: bold;
  padding-bottom: 10px;
  margin-bottom: 10px;
}

aside .mypage_point p:last-of-type span {
  font-size: 13px;
  font-weight: bold;
  color: #a8192e;
  margin-left: 10px;
}

aside .mypage_help {
  margin-top: 20px;
}

aside .mypage_help .mypage_help_header {
  font-size: 14px;
  font-weight: bold;
  color: #435e54;
}

aside .mypage_help .mypage_help_contact {
  margin-top: 5px;
  border: 1px solid #d0d0d0;
  padding: 10px;
  line-height: 1.8;
}

aside .mypage_help .mypage_help_qa {
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

@media screen and (max-width: 767px) {
  aside .mypage_help .mypage_help_qa {
    margin-top: 10px;
  }
}

aside .mypage_help .mypage_help_qa .circle {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: #435e54;
}

@media screen and (max-width: 767px) {
  aside .mypage_help .mypage_help_qa .circle {
    width: 75px;
    height: 75px;
  }
}

aside .mypage_help .mypage_help_qa .circle p {
  color: #fff;
  font-size: 24px;
  font-weight: bold;
}

@media screen and (max-width: 767px) {
  aside .mypage_help .mypage_help_qa .circle p {
    font-size: 30px;
  }
}

aside .mypage_help .mypage_help_qa .circle p span {
  font-size: 16px;
}

@media screen and (max-width: 767px) {
  aside .mypage_help .mypage_help_qa .circle p span {
    font-size: 20px;
  }
}

aside .mypage_help .mypage_help_qa .mypage_help_qa_text {
  width: calc(100% - 60px);
  text-align: right;
}

@media screen and (max-width: 767px) {
  aside .mypage_help .mypage_help_qa .mypage_help_qa_text {
    width: calc(100% - 75px);
  }
}

aside .mypage_help .mypage_help_qa .mypage_help_qa_text p {
  font-weight: bold;
  color: #435e54;
  border-bottom: 1px solid #435e54;
  padding-bottom: 5px;
  margin-bottom: 5px;
}

@media screen and (max-width: 767px) {
  aside .mypage_help .mypage_help_qa .mypage_help_qa_text p {
    font-size: 14px;
  }
}

aside .mypage_help .mypage_help_qa .mypage_help_qa_text a {
  width: 100%;
  max-width: 165px;
  height: 25px;
  color: #435e54;
  font-size: 12px;
  padding: 0 10px;
}

@media screen and (max-width: 767px) {
  aside .mypage_help .mypage_help_qa .mypage_help_qa_text a {
    width: 96%;
    height: 30px;
    max-width: none;
    font-size: 14px;
  }
}

article {
  /* Delivery Address */
  /* Credit Card */
  /* Mypage Top */
  /* Order History */
  /* order End */
  /* Wish List */
}

article h1 {
  padding: 0;
}

article h1 .name {
  font-size: 18px;
  margin-left: 25px;
}

@media screen and (max-width: 767px) {
  article h1 .name {
    font-size: 16px;
    display: block;
    color: #464749;
    margin-left: 0;
    margin-top: 10px;
  }
}

article h1 .name span {
  font-size: 14px;
}

@media screen and (max-width: 767px) {
  article h1 .name span {
    font-size: 12px;
  }
}

article h2 {
  text-align: left;
  border-bottom: 1px solid #d0d0d0;
  padding-bottom: 5px;
}

article form {
  margin-top: 30px;
}

@media screen and (max-width: 767px) {
  article form {
    margin-top: 20px;
  }
}

article > p {
  margin: 30px 0 40px;
}

@media screen and (max-width: 767px) {
  article > p {
    margin: 10px 0;
    font-size: 14px;
  }
}

article > p.order_summary {
  font-size: 14px;
  margin-top: 0;
}

article > p.order_summary .display_block {
  display: block;
  margin-top: 5px;
}

@media screen and (max-width: 767px) {
  article > p.order_summary .display_block {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
  }
}

article > p.order_summary .display_block .btn_cancel {
  width: 130px;
  float: right;
  position: relative;
  top: -70px;
}

@media screen and (max-width: 767px) {
  article > p.order_summary .display_block .btn_cancel {
    float: none;
    position: static;
    font-size: 12px;
    font-weight: normal;
    width: auto;
    height: 20px;
  }
}

article .btn {
  font-size: 16px;
  font-weight: normal;
}

@media screen and (max-width: 767px) {
  article .btn {
    font-size: 14px;
    font-weight: bold;
  }
}

article .btn.popup-modal {
  display: inline-flex;
  min-width: 150px;
  height: 30px;
  padding: 0 10px;
  margin-bottom: 20px;
}

@media screen and (max-width: 767px) {
  article .btn.popup-modal {
    min-width: auto;
    width: 180px;
  }
}

@media screen and (max-width: 767px) {
  article .address_list {
    margin-bottom: 40px;
  }
}

article .address_list dl {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 20px;
  border-bottom: 1px solid #d0d0d0;
}

@media screen and (max-width: 767px) {
  article .address_list dl {
    display: block;
    padding: 10px;
  }
}

article .address_list dl:first-of-type {
  border-top: 1px solid #d0d0d0;
}

article .address_list dl dt {
  width: 200px;
  font-size: 16px;
  font-weight: bold;
}

@media screen and (max-width: 767px) {
  article .address_list dl dt {
    width: 100%;
    margin-bottom: 5px;
    font-size: 14px;
  }
}

article .address_list dl dd {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: calc(100% - 200px);
  padding: 5px 0px 5px 20px;
  border-left: 1px solid #d0d0d0;
  font-size: 16px;
}

@media screen and (max-width: 767px) {
  article .address_list dl dd {
    align-items: flex-start;
    width: 100%;
    padding: 0;
    border-left: 0;
    font-size: 12px;
  }
}

article .address_list dl dd p {
  width: calc(100% - 200px);
  margin-right: 50px;
  line-height: 1.8;
}

@media screen and (max-width: 767px) {
  article .address_list dl dd p {
    width: calc(100% - 80px);
    margin-right: 20px;
    line-height: 1.5;
  }
}

article .address_list dl dd div {
  width: 150px;
}

@media screen and (max-width: 767px) {
  article .address_list dl dd div {
    width: auto;
  }
}

article .address_list dl dd div .btn:not(:first-of-type) {
  margin-top: 10px;
}

@media screen and (max-width: 767px) {
  article .address_list dl dd div .btn {
    display: block;
  }
}

@media screen and (min-width: 768px) {
  article .card_list {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }
}

@media screen and (max-width: 767px) {
  article .card_list {
    margin-bottom: 40px;
  }
}

article .card_list li {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

@media screen and (min-width: 768px) {
  article .card_list li {
    margin-bottom: 10px;
    border: 1px solid #d0d0d0;
    padding: 20px;
    width: calc(50% - 10px);
  }
  article .card_list li:nth-child(odd) {
    margin-right: 20px;
  }
  article .card_list li p {
    font-size: 16px;
  }
}

@media screen and (max-width: 767px) {
  article .card_list li {
    width: 100%;
    border-bottom: 1px solid #d0d0d0;
    padding: 10px 0;
  }
  article .card_list li:first-of-type {
    border-top: 1px solid #d0d0d0;
  }
  article .card_list li p {
    width: 50%;
    padding: 0 5px;
  }
  article .card_list li p span {
    font-size: 14px;
  }
  article .card_list li .btn {
    max-width: 50%;
  }
}

article .card_list li p {
  line-height: 1.8;
}

article .my_block {
  border: 1px solid #d0d0d0;
  padding: 20px;
  margin-bottom: 20px;
}

@media screen and (max-width: 767px) {
  article .my_block {
    padding: 10px;
    margin-bottom: 40px;
  }
}

article .my_block .my_block_heading {
  font-size: 16px;
  font-weight: bold;
  border-bottom: 1px dotted #d0d0d0;
  padding-bottom: 10px;
  margin-bottom: 15px;
}

@media screen and (max-width: 767px) {
  article .my_block .my_block_heading {
    font-size: 14px;
    margin-bottom: 10px;
  }
}

article .my_block .my_block_heading span {
  font-size: 14px;
  font-weight: normal;
}

@media screen and (max-width: 767px) {
  article .my_block .my_block_heading span {
    font-size: 12px;
  }
}

@media screen and (max-width: 767px) {
  article .my_block.my_point .flexbox {
    display: block;
  }
}

article .my_block.my_point .flexbox .point {
  width: 50%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  background-color: #efefef;
  font-size: 16px;
  text-align: center;
}

@media screen and (max-width: 767px) {
  article .my_block.my_point .flexbox .point {
    width: 100%;
    padding: 10px;
    font-size: 14px;
  }
}

article .my_block.my_point .flexbox .point p {
  margin-bottom: 10px;
}

@media screen and (max-width: 767px) {
  article .my_block.my_point .flexbox .point p {
    margin-bottom: 0;
  }
}

article .my_block.my_point .flexbox .point p .attention {
  font-weight: bold;
  margin-left: 5px;
}

article .my_block.my_point .flexbox .point p .attention span {
  font-size: 24px;
}

@media screen and (max-width: 767px) {
  article .my_block.my_point .flexbox .point p .attention span {
    font-size: 16px;
  }
}

article .my_block.my_point .flexbox .point a {
  margin-top: 15px;
}

@media screen and (max-width: 767px) {
  article .my_block.my_point .flexbox .point a {
    margin-top: 10px;
  }
}

article .my_block.my_point .flexbox .barcode {
  width: 50%;
  text-align: center;
}

@media screen and (max-width: 767px) {
  article .my_block.my_point .flexbox .barcode {
    width: 100%;
  }
}

article .my_block.my_point .flexbox .barcode .btn {
  margin-top: 10px;
}

@media screen and (max-width: 767px) {
  article .my_block.my_point .flexbox .barcode .btn {
    display: none;
  }
}

article .my_block.my_coupon table {
  border-collapse: collapse;
  font-size: 14px;
}

@media screen and (max-width: 767px) {
  article .my_block.my_coupon table {
    background-color: #efefef;
    font-size: 12px;
  }
}

article .my_block.my_coupon table thead {
  background-color: #efefef;
}

@media screen and (max-width: 767px) {
  article .my_block.my_coupon table thead {
    display: none;
  }
}

@media screen and (max-width: 767px) {
  article .my_block.my_coupon table tr {
    display: block;
  }
}

article .my_block.my_coupon table th {
  text-align: left;
  padding: 0 10px;
  height: 35px;
}

article .my_block.my_coupon table th:nth-child(1) {
  width: 300px;
}

@media screen and (max-width: 767px) {
  article .my_block.my_coupon table th:nth-child(1) {
    width: 100%;
  }
}

article .my_block.my_coupon table th:nth-child(2) {
  width: 420px;
}

@media screen and (max-width: 767px) {
  article .my_block.my_coupon table th:nth-child(2) {
    width: 100%;
  }
}

article .my_block.my_coupon table th:nth-child(3) {
  width: 240px;
}

@media screen and (max-width: 767px) {
  article .my_block.my_coupon table th:nth-child(3) {
    width: 100%;
  }
}

article .my_block.my_coupon table td {
  padding: 10px;
}

@media screen and (max-width: 767px) {
  article .my_block.my_coupon table td {
    display: block;
    padding: 10px 15px;
  }
}

@media screen and (max-width: 767px) {
  article .my_block.my_coupon table td:nth-child(2) {
    padding-top: 0;
  }
}

article .my_block.my_coupon table td:last-child {
  font-size: 16px;
}

@media screen and (max-width: 767px) {
  article .my_block.my_coupon table td:last-child {
    display: none;
  }
}

article .my_block.my_coupon table td .bold {
  margin-bottom: 5px;
}

@media screen and (max-width: 767px) {
  article .my_block.my_coupon table td .bold {
    text-align: center;
  }
}

article .order {
  font-size: 14px;
}

@media screen and (max-width: 767px) {
  article .order {
    font-size: 12px;
    margin-bottom: 30px;
  }
}

article .order .order_result {
  margin-bottom: 20px;
}

@media screen and (max-width: 767px) {
  article .order .order_result {
    margin-bottom: 10px;
  }
}

article .order ul li {
  border: 1px solid #d0d0d0;
  margin-bottom: 20px;
}

@media screen and (max-width: 767px) {
  article .order ul li {
    margin-bottom: 10px;
  }
}

article .order ul li dt {
  font-weight: bold;
}

article .order ul li .order_date {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  background-color: #efefef;
  border-bottom: 1px solid #d0d0d0;
  padding: 10px;
}

@media screen and (max-width: 767px) {
  article .order ul li .order_date {
    align-items: flex-start;
  }
}

@media screen and (max-width: 767px) {
  article .order ul li .order_date div {
    font-size: 14px;
  }
}

article .order ul li .order_date div dl {
  display: flex;
  flex-wrap: wrap;
}

article .order ul li .order_date div dl dt {
  min-width: 4em;
  margin-bottom: 2px;
}

article .order ul li .order_date .btn {
  width: 130px;
}

@media screen and (max-width: 767px) {
  article .order ul li .order_date .btn {
    font-size: 12px;
    font-weight: normal;
    width: 90px;
    height: 20px;
  }
}

article .order ul li .order_address {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-end;
  border-bottom: 1px dotted #d0d0d0;
  padding-bottom: 10px;
  margin: 10px;
}

@media screen and (max-width: 767px) {
  article .order ul li .order_address {
    display: block;
  }
}

article .order ul li .order_address div dl {
  display: flex;
  flex-wrap: wrap;
}

article .order ul li .order_address div dl dt {
  min-width: 6em;
  margin-bottom: 2px;
}

@media screen and (max-width: 767px) {
  article .order ul li .order_address div dl dd {
    width: calc(100% - 75px);
    padding-left: 1em;
    text-indent: -1em;
  }
}

article .order ul li .order_address p {
  font-weight: bold;
}

@media screen and (max-width: 767px) {
  article .order ul li .order_address p {
    margin-top: 10px;
  }
}

@media screen and (min-width: 768px) {
  article .order ul li .order_address p span {
    font-size: 18px;
  }
}

article .order ul li .order_item {
  margin: 10px;
}

article .order ul li .order_item dl dd {
  margin-top: 5px;
}

@media screen and (max-width: 767px) {
  article .order ul li .order_item dl dd {
    margin-top: 2px;
    font-size: 10px;
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

article .order .order_payment {
  margin-bottom: 20px;
}

@media screen and (min-width: 768px) {
  article .order .order_payment {
    display: grid;
    grid-template-columns: minmax(370px, 625px) minmax(230px, 380px);
  }
  article .order .order_payment .grid1 {
    grid-column: 1 / 2;
    grid-row: 1 / 2;
    margin-right: 30px;
  }
  article .order .order_payment .grid2 {
    grid-column: 1 / 2;
    grid-row: 2 / 3;
    margin-right: 30px;
  }
  article .order .order_payment .grid3 {
    grid-column: 2;
    grid-row: 1 / 3;
  }
  article .order .order_payment .grid3 > p {
    text-align: right;
  }
}

@media screen and (max-width: 767px) {
  article .order .order_payment {
    margin-bottom: 30px;
  }
}

article .order .order_payment .order_payment_block {
  border: 1px solid #d0d0d0;
  padding: 10px;
  margin-bottom: 20px;
}

@media screen and (max-width: 767px) {
  article .order .order_payment .order_payment_block {
    margin-bottom: 10px;
  }
}

article .order .order_payment .order_payment_block > p {
  border-bottom: 1px solid #d0d0d0;
  padding-bottom: 5px;
  margin-bottom: 5px;
  font-weight: bold;
}

@media screen and (max-width: 767px) {
  article .order .order_payment .order_payment_block > p {
    margin-bottom: 5px;
    font-size: 14px;
  }
}

@media screen and (min-width: 768px) {
  article .order .order_payment .order_payment_block div {
    line-height: 1.8;
  }
}

article .order .order_payment .order_payment_block div p span {
  display: inline-block;
  margin-right: 20px;
}

article .order .order_payment .order_payment_block dl {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

article .order .order_payment .order_payment_block dl.line_dotted {
  border-bottom: 1px dotted #d0d0d0;
  padding-bottom: 5px;
  margin-bottom: 5px;
}

@media screen and (max-width: 767px) {
  article .order .order_payment .order_payment_block dl.line_dotted.sp_line_solid {
    border-bottom-style: solid;
  }
}

article .order .order_payment .order_payment_block dl:last-child {
  font-size: 16px;
}

@media screen and (max-width: 767px) {
  article .order .order_payment .order_payment_block dl:last-child {
    font-size: 14px;
  }
}

article .order .order_payment .bill {
  font-weight: bold;
}

@media screen and (max-width: 767px) {
  article .order .order_payment .bill .sp_right {
    justify-content: flex-end;
  }
}

article .order .order_detail {
  border: 1px solid #d0d0d0;
  margin-bottom: 40px;
}

@media screen and (max-width: 767px) {
  article .order .order_detail {
    margin-bottom: 20px;
  }
}

article .order .order_detail .order_detail_date {
  background-color: #efefef;
  padding: 10px;
  border-bottom: 1px solid #d0d0d0;
}

@media screen and (max-width: 767px) {
  article .order .order_detail .order_detail_date {
    border-bottom: 0;
  }
}

article .order .order_detail .order_detail_date dl {
  margin-bottom: 2px;
  display: flex;
  flex-wrap: wrap;
}

article .order .order_detail .order_detail_date dl dt {
  font-weight: bold;
}

article .order .order_detail .order_detail_date dl dt:first-of-type {
  min-width: 6em;
}

@media screen and (max-width: 767px) {
  article .order .order_detail .order_detail_date dl dt {
    min-width: 6em;
  }
}

article .order .order_detail .order_detail_date dl dd {
  margin-right: 20px;
}

@media screen and (max-width: 767px) {
  article .order .order_detail .order_detail_date dl dd {
    margin-right: 0;
    width: calc(100% - 80px);
    padding-left: 1em;
    text-indent: -1em;
  }
}

article .order .order_detail .cart_table {
  font-size: 12px;
}

article .order .order_detail .cart_table .cart_head {
  padding: 20px 10px 15px;
  font-size: 14px;
  border-top: none;
  border-bottom: none;
}

article .order .order_detail .cart_table .cart_data {
  border-bottom: none;
}

@media screen and (max-width: 767px) {
  article .order .order_detail .cart_table .cart_data {
    border: none;
  }
}

article .order .order_detail .cart_table .cart_data:not(.cancel) {
  margin-left: 10px;
  margin-right: 10px;
  border-top: 1px solid #d0d0d0;
}

@media screen and (max-width: 767px) {
  article .order .order_detail .cart_table .cart_data:not(.cancel) {
    margin-left: 0;
    margin-right: 0;
  }
}

@media screen and (min-width: 768px) {
  article .order .order_detail .cart_table .cart_data:not(.cancel) .cart_data_item {
    padding-bottom: 40px;
  }
}

article .order .order_detail .cart_table .cart_data.cancel {
  padding-left: 10px;
  padding-right: 10px;
  background-color: #d0d0d0;
}

article .order .order_detail .cart_table .cart_data.cancel .cart_data_item_image,
article .order .order_detail .cart_table .cart_data.cancel .cart_data_amount,
article .order .order_detail .cart_table .cart_data.cancel .cart_data_price,
article .order .order_detail .cart_table .cart_data.cancel .cart_data_item_detail dl:not(:first-of-type),
article .order .order_detail .cart_table .cart_data.cancel .cart_data_item_detail_type dl {
  opacity: 0.5;
}

@media screen and (min-width: 768px) {
  article .order .order_detail .cart_table .cart_cell.cart_head_item, article .order .order_detail .cart_table .cart_cell.cart_data_item {
    width: calc(100% - 260px);
  }
}

@media screen and (min-width: 768px) {
  article .order .order_detail .cart_table .cart_cell.cart_head_amount, article .order .order_detail .cart_table .cart_cell.cart_data_amount {
    max-width: 100px;
    min-width: 100px;
  }
}

@media screen and (min-width: 768px) {
  article .order .order_detail .cart_table .cart_cell.cart_head_price, article .order .order_detail .cart_table .cart_cell.cart_data_price {
    max-width: 160px;
    min-width: 160px;
  }
}

article .order .order_detail .cart_table .cart_cell.cart_head_item {
  padding-left: 0;
}

@media screen and (min-width: 768px) {
  article .order .order_detail .cart_table .cart_cell.cart_data_item {
    align-items: center;
  }
}

@media screen and (min-width: 768px) {
  article .order .order_detail .cart_table .cart_cell.cart_data_item .cart_data_item_image {
    position: relative;
  }
  article .order .order_detail .cart_table .cart_cell.cart_data_item .cart_data_item_image .btn_review {
    width: 124px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 190px;
  }
}

@media screen and (max-width: 767px) {
  article .order .order_detail .cart_table .cart_cell.cart_data_item .cart_data_item_image .btn_review {
    margin-top: 5px;
  }
}

article .order .order_detail .cart_table .cart_cell.cart_data_item .cart_data_item_detail .stock,
article .order .order_detail .cart_table .cart_cell.cart_data_item .cart_data_item_detail .delivery {
  margin-right: 10px;
  display: inline-block;
}

@media screen and (max-width: 767px) {
  article .order .order_detail .cart_table .cart_cell.cart_data_item .cart_data_item_detail .stock span,
  article .order .order_detail .cart_table .cart_cell.cart_data_item .cart_data_item_detail .delivery span {
    display: inline-block;
    min-width: 5em;
  }
}

@media screen and (max-width: 767px) {
  article .order .order_detail .cart_table .cart_cell.cart_data_amount {
    padding-bottom: 0;
  }
  article .order .order_detail .cart_table .cart_cell.cart_data_amount .cart_cell_inner {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }
  article .order .order_detail .cart_table .cart_cell.cart_data_amount .cart_cell_inner .sp {
    margin-right: 0;
  }
}

@media screen and (max-width: 767px) {
  article .order .order_detail .cart_table .cart_cell.cart_data_price .cart_cell_inner {
    min-height: auto;
  }
}

article .order .btn_return {
  margin-bottom: 10px;
}

@media screen and (max-width: 767px) {
  article .order .btn_return {
    margin: 0 auto 10px;
    width: 200px;
  }
}

article .order .about_return {
  font-size: 14px;
}

@media screen and (max-width: 767px) {
  article .order .about_return {
    text-align: center;
  }
}

article .wishlist {
  margin-top: 40px;
}

@media screen and (max-width: 767px) {
  article .wishlist {
    margin-top: 0;
    margin-bottom: 30px;
  }
}

article .wishlist .cart_table .cart_data {
  align-items: initial;
}

article .wishlist .cart_table .cart_data:first-of-type {
  border-top: 1px solid #d0d0d0;
}

@media screen and (max-width: 767px) {
  article .wishlist .cart_table .cart_data:not(:first-of-type) {
    margin-top: 15px;
  }
}

article .wishlist .cart_table .cart_data .cart_data_item {
  width: 60%;
  min-width: calc(100% - 400px);
}

@media screen and (max-width: 767px) {
  article .wishlist .cart_table .cart_data .cart_data_item {
    width: 100%;
  }
}

article .wishlist .cart_table .cart_data .cart_data_item .cart_data_item_image .btn {
  width: 70px;
  height: 30px;
  margin: 5px auto 0;
  font-size: 12px;
  font-weight: normal;
  border-color: #3b5343;
}

article .wishlist .cart_table .cart_data .cart_data_item .cart_data_item_detail p {
  font-size: 14px;
}

@media screen and (max-width: 767px) {
  article .wishlist .cart_table .cart_data .cart_data_item .cart_data_item_detail p {
    font-size: 12px;
  }
}

article .wishlist .cart_table .cart_data .cart_data_item .cart_data_item_detail .cart_data_item_option {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  justify-content: space-between;
  margin-top: 5px;
}

article .wishlist .cart_table .cart_data .cart_data_item .cart_data_item_detail .cart_data_item_option p {
  margin-top: 2px;
}

article .wishlist .cart_table .cart_data .cart_data_item .cart_data_item_detail .cart_data_item_option p span {
  margin-right: 5px;
}

article .wishlist .cart_table .cart_data .cart_data_item .cart_data_item_detail .cart_data_item_option .btn {
  width: 140px;
  height: 30px;
  font-size: 14px;
  border-color: #3b5343;
}

@media screen and (max-width: 767px) {
  article .wishlist .cart_table .cart_data .stock_status {
    margin-top: 10px;
  }
}

article .wishlist .cart_table .cart_data .cart_data_stock {
  background-color: #efefef;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  width: 10%;
  max-width: 100px;
  font-size: 14px;
}

@media screen and (max-width: 767px) {
  article .wishlist .cart_table .cart_data .cart_data_stock {
    display: none;
  }
}

article .wishlist .cart_table .cart_data .cart_data_action {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  width: 30%;
  max-width: 300px;
  font-size: 14px;
}

@media screen and (max-width: 767px) {
  article .wishlist .cart_table .cart_data .cart_data_action {
    display: block;
    width: 100%;
    max-width: none;
    font-size: 12px;
    padding-top: 0;
  }
}

@media screen and (max-width: 767px) {
  article .wishlist .cart_table .cart_data .cart_data_action div {
    padding: 10px 0;
    border-top: 1px dotted #d0d0d0;
    border-bottom: 1px dotted #d0d0d0;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
  }
}

article .wishlist .cart_table .cart_data .cart_data_action div p {
  display: inline-block;
  margin-right: 10px;
}

@media screen and (max-width: 767px) {
  article .wishlist .cart_table .cart_data .cart_data_action div p {
    margin-right: 0;
  }
}

article .wishlist .cart_table .cart_data .cart_data_action div p span {
  font-weight: bold;
}

article .wishlist .cart_table .cart_data .cart_data_action .btn_delete {
  width: 140px;
  height: 30px;
  font-size: 14px;
  border-color: #3b5343;
}

@media screen and (max-width: 767px) {
  article .wishlist .cart_table .cart_data .cart_data_action .btn_delete {
    width: 120px;
    font-size: 12px;
    font-weight: normal;
  }
}

article .wishlist .cart_table .cart_data .cart_data_action .btn_orange {
  width: 100%;
  height: 50px;
  margin-top: 20px;
  font-size: 14px;
  font-weight: bold;
}

@media screen and (max-width: 767px) {
  article .wishlist .cart_table .cart_data .cart_data_action .btn_orange {
    height: 40px;
    margin-top: 10px;
  }
}

article .wishlist .cart_table .cart_data.cart_data_os .cart_data_item .cart_data_item_detail {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
}

@media screen and (max-width: 767px) {
  article .wishlist .cart_table .cart_data.cart_data_os .cart_data_action div {
    padding-bottom: 0;
    border-bottom: none;
  }
}

/* article End */
@media screen and (max-width: 767px) {
  .contents_wrapper + .suggest_area {
    border-top: none;
  }
}

@media screen and (min-width: 768px) {
  .suggest_area.recent .suggest_area_inner {
    justify-content: center;
  }
  .suggest_area.recent .suggest_area_inner .suggest_item {
    width: 180px;
  }
}
