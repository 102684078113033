@import "_vars";

.contents_wrapper {
  @include max-screen($sp_size) {
    padding-bottom: 0;

    & + .popular {
      margin-top: 30px;
      border-top: none;
    }
  }  
}

aside {
  .mypage_menu_wrap {
    @include max-screen($sp_size) {
      border: 2px solid $color_border_gray;
    }

    a, span {
      display: block;
      text-decoration: none;
      padding: 5px 0;
      padding-left: 20px;
      padding-right: 5px;
      font-weight: bold;
      @include max-screen($sp_size) {
        padding: 12px 0;
        padding-left: 10px;
        padding-right: 5px;
      }
    }
    a {
      text-decoration: none;
      @include min-screen($pc_size) {
        &:hover {
          text-decoration: underline;        
        }
      }
      &.with_arrow_right {
        svg {
          width: 9px;
          @include max-screen($sp_size) {
            right: 10px;
          }
        }      
      }
    }

    .mypage_menu {
      padding: 5px 0;
      border-bottom: 1px solid $color_border_gray;
      @include max-screen($sp_size) {
        padding: 0;
      }
      &:first-of-type {
        padding: 0;
        border-top: 1px solid $color_border_gray;
        @include max-screen($sp_size) {
          border-top: none;
        }
      }
      &:last-of-type {
        @include max-screen($sp_size) {
          border-bottom: none;
        }
      }

      &.current {
        a {
          background-color: $color_bg_gray;        
        }
      }

      ul {
        padding: 5px 0;
        @include max-screen($sp_size) {
          padding: 0;
        }

        li {
          a {
            font-weight: normal;
            padding-left: 40px;
            @include max-screen($sp_size) {
              padding-left: 20px;

            }
          }

          &:first-of-type {
            @include max-screen($sp_size) {
              border-top: 1px dotted $color_border_gray;          
            }
          }

          &:not(:last-of-type) {
            @include max-screen($sp_size) {
              border-bottom: 1px dotted $color_border_gray;            
            }
          }

          &.current {
            a {
              background-color: $color_bg_gray;        
            }
          }
        }
      }
    }
  }

  .mypage_point {
    margin-top: 20px;
    border: 1px solid $color_border_gray;
    padding: 10px;
    @include max-screen($sp_size) {
      display: none;
    }

    p:first-of-type {
      border-bottom: 1px dotted $color_border_gray;
      font-size: 13px;
      font-weight: bold;
      padding-bottom: 10px;
      margin-bottom: 10px;
    }

    p:last-of-type {
      span {
        font-size: 13px;
        font-weight: bold;
        color: $color_attention;
        margin-left: 10px;
      }
    }
  }

  .mypage_help {
    margin-top: 20px;

    .mypage_help_header {
      font-size: 14px;
      font-weight: bold;
      color: $color_main_text;
    }

    .mypage_help_contact {
      margin-top: 5px;
      border: 1px solid $color_border_gray;
      padding: 10px;
      line-height: 1.8;
    }

    .mypage_help_qa {
      margin-top: 20px;
      @include flexbox;
      align-items: center;
      @include max-screen($sp_size) {
        margin-top: 10px;
      }

      .circle{
        @include flexbox;
        align-items: center;
        justify-content: center;
        width: 60px;
        height: 60px;
        border-radius: 50%;
        background-color: $color_main_text;
        @include max-screen($sp_size) {
          width: 75px;
          height: 75px;
        }
        p {
          color: #fff;
          font-size: 24px;
          font-weight: bold;
          @include max-screen($sp_size) {
            font-size: 30px;
          }
          span {
            font-size: 16px;
            @include max-screen($sp_size) {
              font-size: 20px;
            }
          }
        }
      }

      .mypage_help_qa_text {
        width: calc(100% - 60px);
        text-align: right;
        @include max-screen($sp_size) {
          width: calc(100% - 75px);
        }
        p {
          font-weight: bold;
          color: $color_main_text;
          border-bottom: 1px solid $color_main_text;
          padding-bottom: 5px;
          margin-bottom: 5px;
          @include max-screen($sp_size) {
            font-size: 14px;
          }
        }
        a {
          width: 100%;
          max-width: 165px;
          height: 25px;
          color: $color_main_text;
          font-size: 12px;
          padding: 0 10px;
          @include max-screen($sp_size) {
            width: 96%;
            height: 30px;
            max-width: none;
            font-size: 14px;
          }
        }
      }
    }
  }
}

article {

  h1 {
    padding: 0;
    .name {
      font-size: 18px;
      margin-left: 25px;
      @include max-screen($sp_size) {
        font-size: 16px;
        display: block;
        color: $color_text;
        margin-left: 0;
        margin-top: 10px;
      }
      span {
        font-size: 14px;
        @include max-screen($sp_size) {
          font-size: 12px;
        }
      }
    }
  }

  h2 {
    text-align: left;
    border-bottom: 1px solid $color_border_gray;
    padding-bottom: 5px;
  }

  form {
    margin-top: 30px;
    @include max-screen($sp_size) {
      margin-top: 20px;
    }
  }

  > p {
    margin: 30px 0 40px;
    @include max-screen($sp_size) {
      margin: 10px 0;
      font-size: 14px;
    }

    &.order_summary {
      font-size: 14px;
      margin-top: 0;

      .display_block {
        display: block;
        margin-top: 5px;
        @include max-screen($sp_size) {
          @include flexbox;
          align-items: center;
          justify-content: space-between;
        }

        .btn_cancel {
          width: 130px;
          float: right;
          position: relative;
          top: -70px;
          @include max-screen($sp_size) {
            float: none;
            position: static;
            font-size: 12px;
            font-weight: normal;
            width: auto;
            height: 20px;
          }
        }
      }
    }
  }

  .btn {
    font-size: 16px;
    font-weight: normal;
    @include max-screen($sp_size) {
      font-size: 14px;
      font-weight: bold;
    }
  }

  .btn.popup-modal {
    display: inline-flex;
    min-width: 150px;
    height: 30px;
    padding: 0 10px;
    margin-bottom: 20px;
    @include max-screen($sp_size) {
      min-width: auto;
      width: 180px;
    }
  }

  /* Delivery Address */
  .address_list {
    @include max-screen($sp_size) {
      margin-bottom: 40px;      
    }

    dl {
      @include flexbox;
      align-items: center;
      padding: 20px;
      border-bottom: 1px solid $color_border_gray;
      @include max-screen($sp_size) {
        display: block;
        padding: 10px;
      }

      &:first-of-type {
        border-top: 1px solid $color_border_gray;
      }

      dt {
        width: 200px;
        font-size: 16px;
        font-weight: bold;
        @include max-screen($sp_size) {
          width: 100%;
          margin-bottom: 5px;
          font-size: 14px;
        }
      }
      dd {
        @include flexbox;
        align-items: center;
        width: calc(100% - 200px);
        padding: 5px 0px 5px 20px;
        border-left: 1px solid $color_border_gray;
        font-size: 16px;
        @include max-screen($sp_size) {
          align-items: flex-start;
          width: 100%;
          padding: 0;
          border-left: 0;
          font-size: 12px;
        }
        
        p {
          width: calc(100% - 200px);
          margin-right: 50px;
          line-height: 1.8;
          @include max-screen($sp_size) {
            width: calc(100% - 80px);
            margin-right: 20px;
            line-height: 1.5;
          }
        }
        div {
          width: 150px;
          @include max-screen($sp_size) {
            width: auto;
          }
          .btn {
            &:not(:first-of-type) {
              margin-top: 10px;
            }

            @include max-screen($sp_size) {
              display: block;
            }
          }
        }
      }
    }
  }

  /* Credit Card */
  .card_list {
    @include min-screen($pc_size) {
      @include flexbox;
      align-items: center;
    }
    @include max-screen($sp_size) {
      margin-bottom: 40px;      
    }

    li {
      @include flexbox;
      align-items: center;
      justify-content: space-between;
      @include min-screen($pc_size) {
        margin-bottom: 10px;
        border: 1px solid $color_border_gray;
        padding: 20px;
        width: calc(50% - 10px);

        &:nth-child(odd) {
          margin-right: 20px;
        }

        p {
          font-size: 16px;
        }
      }
      @include max-screen($sp_size) {
        width: 100%;
        border-bottom: 1px solid $color_border_gray;
        padding: 10px 0;
        &:first-of-type {
          border-top: 1px solid $color_border_gray;
        }

        p {
          width: 50%;
          padding: 0 5px;

          span {
            font-size: 14px;
          }
        }
        .btn {
          max-width: 50%;
        }
      }

      p {
        line-height: 1.8;
      }
    }
  }

  /* Mypage Top */
  .my_block {
    border: 1px solid $color_border_gray;
    padding: 20px;
    margin-bottom: 20px;
    @include max-screen($sp_size) {
      padding: 10px;
      margin-bottom: 40px;
    }

    .my_block_heading {
      font-size: 16px;
      font-weight: bold;
      border-bottom: 1px dotted $color_border_gray;
      padding-bottom: 10px;
      margin-bottom: 15px;
      @include max-screen($sp_size) {
        font-size: 14px;
        margin-bottom: 10px;
      }
      span {
        font-size: 14px;
        font-weight: normal;
        @include max-screen($sp_size) {
          font-size: 12px;
        }
      }
    }

    &.my_point {
      .flexbox {
        @include max-screen($sp_size) {
          display: block;
        }
        .point {
          width: 50%;
          @include flexbox;
          flex-direction: column;
          justify-content: center;
          background-color: $color_bg_gray;
          font-size: 16px;
          text-align: center;
          @include max-screen($sp_size) {
            width: 100%;
            padding: 10px;
            font-size: 14px;
          }

          p {
            margin-bottom: 10px;
            @include max-screen($sp_size) {
              margin-bottom: 0;
            }
            .attention {
              font-weight: bold;
              margin-left: 5px;
              span {
                font-size: 24px;
                @include max-screen($sp_size) {
                  font-size: 16px;
                }
              }
            }
          }

          a {
            margin-top: 15px;
            @include max-screen($sp_size) {
              margin-top: 10px;
            }
          }
        }
        .barcode {
          width: 50%;
          text-align: center;
          @include max-screen($sp_size) {
            width: 100%;
          }

          .btn {
            margin-top: 10px;
            @include max-screen($sp_size) {
              display: none;
            }
          }
        }        
      }
    }

    &.my_coupon {
      table {
        border-collapse: collapse;
        font-size: 14px;
        @include max-screen($sp_size) {
          background-color: $color_bg_gray;
          font-size: 12px;
        }

        thead {
          background-color: $color_bg_gray;
          @include max-screen($sp_size) {
            display: none;
          }
        }
        tr {
          @include max-screen($sp_size) {
            display: block;
          }
        }
        th {
          text-align: left;
          padding: 0 10px;
          height: 35px;

          &:nth-child(1) {
            width: 300px;
            @include max-screen($sp_size) {
              width: 100%;
            }
          }
          &:nth-child(2) {
            width: 420px;
            @include max-screen($sp_size) {
              width: 100%;
            }
          }
          &:nth-child(3) {
            width: 240px;
            @include max-screen($sp_size) {
              width: 100%;
            }
          }
        }
        td {
          padding: 10px;
          @include max-screen($sp_size) {
            display: block;
            padding: 10px 15px;
          }

          &:nth-child(2) {
            @include max-screen($sp_size) {
              padding-top: 0;
            }
          }

          &:last-child {
            font-size: 16px;
            @include max-screen($sp_size) {
              display: none;
            }
          }

          .bold {
            margin-bottom: 5px;
            @include max-screen($sp_size) {
              text-align: center;
            }
          }
        }
      }
    }
  }

  /* Order History */
  .order {
    font-size: 14px;
    @include max-screen($sp_size) {
      font-size: 12px;
      margin-bottom: 30px;
    }

    .order_result {
      margin-bottom: 20px;
      @include max-screen($sp_size) {
        margin-bottom: 10px;
      }
    }

    ul {
      li {
        border: 1px solid $color_border_gray;
        margin-bottom: 20px;
        @include max-screen($sp_size) {
          margin-bottom: 10px;
        }

        dt {
          font-weight: bold;
        }

        .order_date {
          @include flexbox;
          justify-content: space-between;
          align-items: center;
          background-color: $color_bg_gray;
          border-bottom: 1px solid $color_border_gray;
          padding: 10px;
          @include max-screen($sp_size) {
            align-items: flex-start;
          }

          div {
            @include max-screen($sp_size) {
              font-size: 14px;
            }
            dl {
              @include flexbox;

              dt {
                min-width: 4em;
                margin-bottom: 2px;
              }
            }            
          }

          .btn {
            width: 130px;
            @include max-screen($sp_size) {
              font-size: 12px;
              font-weight: normal;
              width: 90px;
              height: 20px;
            }
          }
        }

        .order_address {
          @include flexbox;
          justify-content: space-between;
          align-items: flex-end;
          border-bottom: 1px dotted $color_border_gray;
          padding-bottom: 10px;
          margin: 10px;
          @include max-screen($sp_size) {
            display: block;
          }

          div {
            dl {
              @include flexbox;

              dt {
                min-width: 6em;
                margin-bottom: 2px;
              }
              dd {
                @include max-screen($sp_size) {
                  width: calc(100% - 75px);
                  padding-left: 1em;
                  text-indent: -1em;
                }
              }
            }
          }

          p {
            font-weight: bold;
            @include max-screen($sp_size) {
              margin-top: 10px;
            }

            span {
              @include min-screen($pc_size) {
                font-size: 18px;
              }
            }
          }   
        }

        .order_item {
          margin: 10px;
          dl {
            dd {
              margin-top: 5px;
              @include max-screen($sp_size) {
                margin-top: 2px;
                font-size: 10px;
                width: 100%;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
              }
            }
          } 
        }
      }      
    }

    // Order Detail
    .order_payment {
      margin-bottom: 20px;
      @include min-screen($pc_size) {
        display: grid;
        grid-template-columns: minmax(370px, 625px) minmax(230px, 380px);

        .grid1 {
          grid-column: 1 / 2;
          grid-row: 1 / 2;
          margin-right: 30px;
        }
        .grid2 {
          grid-column: 1 / 2;
          grid-row: 2 / 3;
          margin-right: 30px;
        }
        .grid3 {
          grid-column: 2;
          grid-row: 1 / 3;

          > p {
            text-align: right;
          }
        }
      }
      @include max-screen($sp_size) {
        margin-bottom: 30px;
      }

      .order_payment_block {
        border: 1px solid $color_border_gray;
        padding: 10px;
        margin-bottom: 20px;
        @include max-screen($sp_size) {
          margin-bottom: 10px;
        }

        > p {
          border-bottom: 1px solid $color_border_gray;
          padding-bottom: 5px;
          margin-bottom: 5px;
          font-weight: bold;
          @include max-screen($sp_size) {
            margin-bottom: 5px;
            font-size: 14px;
          }
        }

        div {
          @include min-screen($pc_size) {
            line-height: 1.8;            
          }

          p {
            span {
              display: inline-block;
              margin-right: 20px;              
            }
          }
        }

        dl {
          @include flexbox;
          justify-content: space-between;

          &.line_dotted {
            border-bottom: 1px dotted $color_border_gray;
            padding-bottom: 5px;
            margin-bottom: 5px;

            &.sp_line_solid {
              @include max-screen($sp_size) {
                border-bottom-style: solid;
              }
            }
          }

          &:last-child {
            font-size: 16px;
            @include max-screen($sp_size) {
              font-size: 14px;
            }
          }
        }
      }

      .bill {
        font-weight: bold;

        .sp_right {
          @include max-screen($sp_size) {
            justify-content: flex-end;            
          }
        }
      }
    }

    .order_detail {
      border: 1px solid $color_border_gray;
      margin-bottom: 40px;
      @include max-screen($sp_size) {
        margin-bottom: 20px;
      }

      .order_detail_date {
        background-color: $color_bg_gray;
        padding: 10px;
        border-bottom: 1px solid $color_border_gray;
        @include max-screen($sp_size) {
          border-bottom: 0;
        }

        dl {
          margin-bottom: 2px;
          @include flexbox;
          dt {
            font-weight: bold;

            &:first-of-type {
              min-width: 6em;
            }

            @include max-screen($sp_size) {
              min-width: 6em;
            }
          }

          dd {
            margin-right: 20px;
            @include max-screen($sp_size) {
              margin-right: 0;
              width: calc(100% - 80px);
              padding-left: 1em;
              text-indent: -1em;
            }
          }
        }
      }

      .cart_table {
        font-size: 12px;

        .cart_head {
          padding: 20px 10px 15px;
          font-size: 14px;
          border-top: none;
          border-bottom: none;
        }

        .cart_data {
          border-bottom: none;
          @include max-screen($sp_size) {
            border: none;
          }

          &:not(.cancel) {
            margin-left: 10px;
            margin-right: 10px;
            border-top: 1px solid $color_border_gray;
            @include max-screen($sp_size) {
              margin-left: 0;
              margin-right: 0;
            }

            .cart_data_item {
              @include min-screen($pc_size) {
                padding-bottom: 40px;
              }
            }
          }

          &.cancel {
            padding-left: 10px;
            padding-right: 10px;
            background-color: $color_border_gray;
            .cart_data_item_image,
            .cart_data_amount,
            .cart_data_price,
            .cart_data_item_detail dl:not(:first-of-type),
            .cart_data_item_detail_type dl {
              opacity: 0.5;
            }
          }
        }

        .cart_cell {
          &.cart_head_item,
          &.cart_data_item {
            @include min-screen($pc_size) {
              width: calc(100% - 260px);
            }
          }
          &.cart_head_amount,
          &.cart_data_amount {
            @include min-screen($pc_size) {
              max-width: 100px;
              min-width: 100px;
            }
          }
          &.cart_head_price,
          &.cart_data_price {
            @include min-screen($pc_size) {
              max-width: 160px;
              min-width: 160px;
            }
          }

          &.cart_head_item {
            padding-left: 0;
          }

          &.cart_data_item {
            @include min-screen($pc_size) {
              align-items: center;
            }

            .cart_data_item_image {
              @include min-screen($pc_size) {
                position: relative;          
                .btn_review {
                  width: 124px;
                  @include centering_horizontally;
                  top: 190px;
                }                
              }
              @include max-screen($sp_size) {
                .btn_review {
                  margin-top: 5px;
                }
              }
            }

            .cart_data_item_detail {
              .stock,
              .delivery {
                margin-right: 10px;
                display: inline-block;
                span {
                  @include max-screen($sp_size) {
                    display: inline-block;
                    min-width: 5em;
                  }
                }
              }
            }
          }

          &.cart_data_amount {
            @include max-screen($sp_size) {
              padding-bottom: 0;
              .cart_cell_inner {
                @include flexbox;
                align-items: center;
                .sp {
                  margin-right: 0;
                }                              
              }
            }
          }

          &.cart_data_price {
            .cart_cell_inner {
              @include max-screen($sp_size) {
                min-height: auto;
              }              
            }
          }
        }
      }
    }
    .btn_return {
      margin-bottom: 10px;
      @include max-screen($sp_size) {
        margin: 0 auto 10px;
        width: 200px;
      }
    }
    .about_return {
      font-size: 14px;
      @include max-screen($sp_size) {
        text-align: center;
      }
    }
  }/* order End */

  /* Wish List */
  .wishlist {
    margin-top: 40px;
    @include max-screen($sp_size) {
      margin-top: 0;
      margin-bottom: 30px;
    }

    .cart_table {
      .cart_data {
        align-items: initial;
        &:first-of-type {
          border-top: 1px solid $color_border_gray;
        }

        &:not(:first-of-type) {
          @include max-screen($sp_size) {
            margin-top: 15px;
          }
        }

        .cart_data_item {
          width: 60%;
          min-width: calc(100% - 400px);
          @include max-screen($sp_size) {
            width: 100%;            
          }

          .cart_data_item_image {
            .btn {
              width: 70px;
              height: 30px;
              margin: 5px auto 0;
              font-size: 12px;
              font-weight: normal;
              border-color: $color_main_dark;
            }
          }

          .cart_data_item_detail {
            p {
              font-size: 14px;
              @include max-screen($sp_size) {
                font-size: 12px;
              }
            }
            .cart_data_item_option {
              @include flexbox;
              align-items: flex-end;
              justify-content: space-between;
              margin-top: 5px;
              p {
                margin-top: 2px;
                span {
                  margin-right: 5px;
                }                
              }
              .btn {
                width: 140px;
                height: 30px;
                font-size: 14px;
                border-color: $color_main_dark;
              }
            }
          }
        }

        .stock_status {
          @include max-screen($sp_size) {
            margin-top: 10px;            
          }
        }
        .cart_data_stock {
          background-color: $color_bg_gray;
          @include flexbox;
          align-items: center;
          justify-content: center;
          width: 10%;
          max-width: 100px;
          font-size: 14px;
          @include max-screen($sp_size) {
            display: none;
          }
        }

        .cart_data_action {
          @include flexbox;
          flex-direction: column;
          justify-content: center;
          width: 30%;
          max-width: 300px;
          font-size: 14px;
          @include max-screen($sp_size) {
            display: block;
            width: 100%;
            max-width: none;
            font-size: 12px;
            padding-top: 0;
          }

          div {
            @include max-screen($sp_size) {
              padding: 10px 0;
              border-top: 1px dotted $color_border_gray;
              border-bottom: 1px dotted $color_border_gray;
              @include flexbox;
              align-items: center;
              justify-content: space-between;
            }

            p {
              display: inline-block;              
              margin-right: 10px;
              @include max-screen($sp_size) {
                margin-right: 0;
              }

              span {
                font-weight: bold;
              }
            }
          }
          .btn_delete {
            width: 140px;
            height: 30px;
            font-size: 14px;
            border-color: $color_main_dark;
            @include max-screen($sp_size) {
              width: 120px;
              font-size: 12px;
              font-weight: normal;
            }
          }
          .btn_orange {
            width: 100%;
            height: 50px;
            margin-top: 20px;
            font-size: 14px;
            font-weight: bold;
            @include max-screen($sp_size) {
              height: 40px;
              margin-top: 10px;
            }
          }
        }

        &.cart_data_os {
          .cart_data_item {
            .cart_data_item_detail {
              @include flexbox;
              flex-direction: column;
              justify-content: center;
            }
          }
          .cart_data_action {
            div {
              @include max-screen($sp_size) {
                padding-bottom: 0;
                border-bottom: none;
              }
            }
          }
        }
      }
    }
  }

} /* article End */


.contents_wrapper + .suggest_area {
  @include max-screen($sp_size) {
    border-top: none;
  }
}
.suggest_area {
  &.recent {
    @include min-screen($pc_size) {
      .suggest_area_inner {
        justify-content: center;
        .suggest_item {
          width: 180px;
        }
      }
    }
  }
}
